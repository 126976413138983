<template>
  <div class="event-marketing-info">
    <p class="card-title">活動資訊</p>
    <BaseElForm
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem label="活動名稱" prop="name" required>
        <BaseElInput v-model="formData.name" placeholder="請輸入" />
      </BaseElFormItem>
      <BaseElFormItem label="活動期間" prop="date" required>
        <el-date-picker
          v-model="formData.date"
          editable
          type="datetimerange"
          format="yyyy-MM-dd HH:mm"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        />
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import dayjs from '@/lib/dayjs'
import { noEmptyRules } from '@/validation'
import { find, get, set } from 'lodash'

export default defineComponent({
  name: 'EventMarketingInfo',
  props: ['FormsContext', 'eventData'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const formData = reactive({
      name: null,
      date: null,
    })

    const pickerOptions = {
      disabledDate (time) {
        return time.getTime() < dayjs(Date.now()).subtract(1, 'd')
      },
    }

    const formRules = {
      name: [noEmptyRules('請輸入活動名稱')],
      date: [noEmptyRules('請輸入活動期間')],
    }

    const compactData = computed(() => {
      const data = {
        name: get(formData, 'name'),
      }
      if (get(formData, 'date')) {
        data.startAt = get(formData, 'date')[0]
        data.endAt = get(formData, 'date')[1]
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      set(formData, 'name', data.name)
      formData.date = [data.startAt, data.endAt]
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('info', formRef.value)
    })

    watch(formData, () => {
      props.FormsContext.setFormData('info', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      pickerOptions,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-date-editor {
  @apply w-[560px];
}
</style>
