<template>
  <div class="event-appointment-product">
    <BaseElSelect
      v-model="currentProduct"
      popper-class="select-popper"
      placeholder="請選擇訂閱方案"
      clearable
      multiple
      :disabled="isDisabled"
      @visible-change="showProductSelectModal = true"
    >
      <BaseElSelectOption
        v-for="product in currentProduct"
        :key="product"
        :label="findProductName(product)"
        :value="product"
      />
    </BaseElSelect>

    <SubscriptionPlanAdvanceSelect
      v-if="showProductSelectModal"
      :data="subscriptionProduct"
      :select="currentProduct"
      :typeOptions="productTypeConfig"
      @confirm="onAddProduct"
      @close="showProductSelectModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import SubscriptionPlanAdvanceSelect from '@/components/Select/SubscriptionPlanAdvanceSelect.vue'
import { productTypeConfig } from '@/config/memberShop'
import { GetSubscribePlan, GetSubscribePlanCount } from '@/api/subscribe'
import store from '@/store'
import { getAllDataFromApi } from '@/utils/helper'
import { find } from 'lodash'

export default defineComponent({
  name: 'EventAppointmentProduct',
  components: { SubscriptionPlanAdvanceSelect },
  props: ['planId', 'isDisabled'],
  emits: ['update:planId'],
  setup (props, { emit }) {
    const showProductSelectModal = ref(false)
    const subscriptionProduct = ref([])
    const shopId = computed(() => store.getters.shop)

    const currentProduct = computed({
      get: () => props.planId,
      set: (newValue) => emit('update:planId', newValue),
    })
    const findProductName = (id) => {
      console.log('findProductName', id)
      const product = find(subscriptionProduct.value, { id })
      if (product) return product.name
      return ''
    }

    const onAddProduct = (product) => {
      if (product.length > 0) {
        console.log('onAddProduct', product)
        currentProduct.value = product
      }
    }

    const getProductCount = async () => {
      const [res, err] = await GetSubscribePlanCount({
        shopId: shopId.value,
      })
      if (err) throw err
      return res.count
    }

    const getAllSubscriptionProducts = async () => {
      let max
      try {
        max = await getProductCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(max, GetSubscribePlan, config, true)
      if (err) return window.$message.error(err)
      subscriptionProduct.value = res
    }

    onMounted(() => {
      getAllSubscriptionProducts()
    })

    return {
      subscriptionProduct,
      currentProduct,
      findProductName,
      onAddProduct,
      showProductSelectModal,
      productTypeConfig,
    }
  },
})
</script>
