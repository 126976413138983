<template>
  <div class="event-appointment-product">
    <BaseElSelect
      v-model="currentProduct"
      popper-class="select-popper"
      placeholder="選擇服務"
      clearable
      multiple
      :disabled="isDisabled"
      @visible-change="showProductSelectModal = true"
    >
      <BaseElSelectOption
        v-for="product in currentProduct"
        :key="product"
        :label="findProductName(product)"
        :value="product"
      />
    </BaseElSelect>

    <AppointmentAdvanceSelect
      v-if="showProductSelectModal"
      :data="storeProducts"
      :select="currentProduct"
      multiple
      :typeOptions="productTypeConfig"
      @confirm="onAddProduct"
      @close="showProductSelectModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import AppointmentAdvanceSelect from '@/components/Select/AppointmentAdvanceSelect.vue'
import { productTypeConfig } from '@/config/memberShop'
import { GetServiceNew, GetServiceCountNew } from '@/api/service'
import store from '@/store'
import { getAllDataFromApi } from '@/utils/helper'
import { find } from 'lodash'

export default defineComponent({
  name: 'EventAppointmentProduct',
  components: { AppointmentAdvanceSelect },
  props: ['productId', 'isDisabled'],
  emits: ['update:productId'],
  setup (props, { emit }) {
    const showProductSelectModal = ref(false)
    const storeProducts = ref([])
    const shopId = computed(() => store.getters.shop)

    const currentProduct = computed({
      get: () => props.productId,
      set: (newValue) => emit('update:productId', newValue),
    })

    const findProductName = (id) => {
      const product = find(storeProducts.value, { id })
      if (product) return product.name
      return ''
    }

    const onAddProduct = (product) => {
      if (product.length > 0) {
        currentProduct.value = product
      }
    }

    const getProductCount = async () => {
      const [res, err] = await GetServiceCountNew({
        shopId: shopId.value,
      })
      if (err) throw err
      return res.count
    }

    const getAllStoreProducts = async () => {
      let max
      try {
        max = await getProductCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetServiceNew,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      storeProducts.value = res
    }

    onMounted(() => {
      getAllStoreProducts()
    })

    return {
      storeProducts,
      currentProduct,
      findProductName,
      onAddProduct,
      showProductSelectModal,
      productTypeConfig,
    }
  },
})
</script>
