<template>
  <el-dialog
    title="指定訂閱方案"
    :visible="true"
    width="700px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <header class="flex" style="gap: 16px">
      <BaseElInput v-model="search.name" placeholder="請輸入訂閱方案名稱" clearable>
        <i slot="suffix" class="el-input__icon el-icon-search" />
      </BaseElInput>
    </header>

    <main>
      <div
        class="grid-container text-primary-100"
        style="padding-top: 21px; padding-bottom: 16px"
      >
        <BaseElCheckbox label="訂閱方案名稱" :disabled="!multiple" @change="selectAll">
          <p class="text-primary-100">訂閱方案名稱</p>
        </BaseElCheckbox>
        <p class="text-right">訂閱方案價格</p>
      </div>

      <BaseElCheckboxGroup v-model="selected">
        <div
          v-for="product in displayData[tableOptions.page - 1]"
          :key="product.id"
        >
          <hr>
          <div class="grid-container" style="padding: 16px 0">
            <BaseElCheckbox
              :label="product.id"
              :disabled="disabledProduct(product.id)"
            >
              {{ product.name }}
            </BaseElCheckbox>
            <p class="text-right">
              $ {{ product.totalAmount || '-' }}
            </p>
          </div>
        </div>
      </BaseElCheckboxGroup>
    </main>

    <div>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="flattenDeep(displayData).length"
      />
    </div>

    <div class="flex justify-end items-center" style="padding-top: 26px">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" :disabled="!selected.length" @click="onSubmit">
        確認
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { map, chunk, get, flattenDeep } from 'lodash'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'SubscriptionPlanAdvanceSelect',
  props: {
    typeOptions: {
      type: Object,
      default: () => ({ none: { label: '暫無資料', value: 'none' } }),
    },
    data: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },
    max: { type: [Number, null], default: null },
    select: { type: Array, default: () => [] },
  },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { tableData, tableDataCount, tableOptions } = useTable()
    tableOptions.pageLimit = 8
    const displayData = computed(() => {
      let data = [...props.data]
      if (search.name) data = data.filter((i) => i.name.includes(search.name))
      if (search.type) data = data.filter((i) => i.type === search.type)
      return chunk(data, tableOptions.pageLimit)
    })
    const disabledProduct = (productId) => {
      let disabled = false
      if (props.max) {
        if (selected.value.length === props.max) {
          if (selected.value.includes(productId)) return false
          return true
        }
      }
      if (props.multiple) return false
      if (!selected.value.length) return false
      if (selected.value.includes(productId)) disabled = false
      else disabled = true
      return disabled
    }
    const search = reactive({
      name: null,
      type: null,
    })
    const selected = ref([])

    const selectAll = (all) => {
      if (all) {
        selected.value = map(displayData.value[tableOptions.page - 1], 'id')
        if (props.max) selected.value = selected.value.slice(0, props.max)
      } else selected.value = []
    }

    const onSubmit = () => {
      emit('confirm', selected.value)
      emit('close')
    }

    onMounted(() => {
      tableData.value = props.data
      tableDataCount.value = props.data.length
      selected.value = props.select
    })

    return {
      get,
      flattenDeep,
      onSubmit,
      search,
      selected,
      selectAll,
      displayData,
      tableDataCount,
      tableOptions,
      disabledProduct,
    }
  },
})
</script>

<style scoped lang="postcss">
.grid-container {
  @apply grid gap-[36px];
  @apply text-normal;
  grid-template-columns: 1fr 120px;
}
::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}
</style>
