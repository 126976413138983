<template>
  <div v-if="showCmp">
    <BaseElFormItem label="累積計算方式" prop="sumUp.type" :rules="[noEmptyRules()]">
      <BaseElSelect
        v-model="syncForm.sumUp.type"
        :disabled="isEdit"
        @change="setAccumulation"
      >
        <BaseElSelectOption
          v-for="item in filterEventSumUpConfigConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
    </BaseElFormItem>

    <BaseElFormItem label="累積推薦人數設置" prop="amountConfig.amountType" :rules="[noEmptyRules()]">
      <BaseElRadioGroup
        v-model="syncForm.amountConfig.amountType"
        :disabled="isEdit"
        class="flex flex-col"
        style="gap: 20px; padding-top: 8px"
        @change="amountTypeChangeHandler"
      >
        <div>
          <BaseElRadio label="threshold">
            達成指定人數
            <div
              class="flex items-center gap-[20px]"
              style="margin-left: 25px; margin-top: 8px; gap: 20px"
            >
              累積推薦滿
              <BaseElFormItem
                v-if="syncForm.source === 'memberReferralRecordSuccessReferrer'"
                prop="amountConfig.amountMin"
                :rules="[noEmptyRules(), isDigitRules(true), minRules(1)]"
              >
                <BaseElInput
                  v-model="syncForm.amountConfig.amountMin"
                  :disabled="isEdit"
                  style="width: 100px"
                  placeholder="請輸入"
                />
              </BaseElFormItem>
              <span v-else>1</span>
              人
            </div>
          </BaseElRadio>
        </div>
      </BaseElRadioGroup>
    </BaseElFormItem>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import { useVModel } from '@vueuse/core'
import {
  eventSumUpConfigConfig,
} from '@/config/marketing'
import { filter } from 'lodash'
import { noEmptyRules, isDigitRules, minRules } from '@/validation'

export default defineComponent({
  name: 'AmountRulesSettingBlock',
  props: {
    form: { type: Object, default: () => ({}) },
    isEdit: Boolean,
    setAccumulation: Function,
    amountTypeChangeHandler: Function,
  },
  emits: ['update:form'],
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)
    const showCmp = computed(() => {
      return ['memberReferralRecordSuccessReferrer', 'memberReferralRecordSuccessReferee'].includes(syncForm.value.source)
    })

    const filterEventSumUpConfigConfig = computed(() => {
      const sourceType = syncForm.value.source
      // 新會員
      if (sourceType === 'memberReferralRecordSuccessReferee') {
        return filter(eventSumUpConfigConfig, i => i.value === 'once')
      }

      return eventSumUpConfigConfig
    })

    onMounted(() => {
      if (syncForm.value.source === 'memberReferralRecordSuccessReferee') {
        syncForm.value.amountConfig.amountMin = 1
      }
    })

    return { syncForm, filterEventSumUpConfigConfig, showCmp, noEmptyRules, isDigitRules, minRules }
  },
})
</script>

<style lang="postcss" scoped>

</style>
